export class NavigatorUtils {
  static isMobileEnvironment(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static isInsideApp(): boolean {
    const standalone = (window.navigator as any).standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isSafari = /safari/.test(userAgent);
    const isIos = /iphone|ipod|ipad/.test(userAgent);
    return (isIos && !standalone && !isSafari) || userAgent.includes('wv');
  }

  static get minMobileWidth(): number {
    return 768;
  }
}
